import styled from 'styled-components';

import ItemClose from '../../../assets/icons/item_close.inline.svg';
import ItemOpen from '../../../assets/icons/item_open.inline.svg';
import { getColor, Color } from '../../../theme/color';
import { getUnitAsPixels } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const ShowMoreContainer = styled.div`
  cursor: pointer;
  ${getTypography(Type.BODY_SMALL)}
  display: inline-flex;
  align-items: center;
  > span {
    margin-right: ${getUnitAsPixels()};
  }
  :hover {
    color: ${getColor(Color.RED)};
  }
  margin-top: ${getUnitAsPixels({ multiplier: 3 })};
`;

export const Icon = styled.div`
  position: relative;
  ${getTypography(Type.BODY_SMALL_ICON)}
  display: flex;
  align-items: center;
  path {
    stroke: ${getColor(Color.RED)};
  }
`;

export const Open = styled(ItemOpen)<{ isShowMore: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  z-index: ${({ isShowMore }) => (isShowMore ? -1 : 1)};
  opacity: ${({ isShowMore }) => (isShowMore ? 0 : 1)};
`;

export const Close = styled(ItemClose)<{ isShowMore: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  z-index: ${({ isShowMore }) => (isShowMore ? 1 : -1)};
  opacity: ${({ isShowMore }) => (isShowMore ? 1 : 0)};
`;
