import { useState, useMemo } from 'react';

export const useFilters = (
  defaultValue: string = 'All',
  items: any[],
  sortType: string = 'string',
  key: string = 'category',
) => {
  const [activeFilter, setActiveFilter] = useState(defaultValue);
  const sort = (arr: any[]) => {
    switch (sortType) {
      case 'number':
        return arr.sort((a, b) => b - a);
      case 'string':
        return arr.sort();
      default:
        return arr;
    }
  };
  const filters = [
    ...new Set([defaultValue, ...sort(items.map((item) => item[key]))]),
  ];

  const filterItems = () => {
    if (activeFilter === defaultValue) return items;

    return items.filter((item) => item[key] === activeFilter);
  };

  const filteredItems = useMemo(filterItems, [
    items,
    activeFilter,
    defaultValue,
    key,
  ]);

  const chageFilter = (filter: string) => {
    setActiveFilter(filter);
  };

  return { filters, activeFilter, filteredItems, chageFilter };
};
