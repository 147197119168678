import { graphql } from 'gatsby';
import { get } from 'lodash';
import React, { Fragment } from 'react';

import { getSchema } from '../common/schema';
import { ModuleNews } from '../components/blocks/moduleNews';
import { SEO } from '../components/common/seo';
import { useLocale } from '../context/locale';

export const query = graphql`
  query NewsHomeQuery($id: String!) {
    news: allDatoCmsNewsArticle(
      filter: { slug: { ne: null } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        locale
        id
        slug
        category
        date
        description
        previewImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { crop: "focalpoint", fit: "crop", ar: "16:9" }
          )
        }
      }
    }
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsNewsHome(id: { eq: $id }) {
      model {
        apiKey
      }
      title
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    versions: allDatoCmsNewsHome {
      nodes {
        locale
        model {
          apiKey
        }
      }
    }
  }
`;

const NewsHome = ({ data, pageContext }: any) => {
  const locale = useLocale();
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const versions = get(data, 'versions.nodes');
  const news = get(data, 'news.nodes').filter((n) => n.locale === locale);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  return (
    <>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <ModuleNews title={page.title} items={news} />
    </>
  );
};

export default NewsHome;
