import React from 'react';

import CloseSVG from '../../../assets/icons/item_close.inline.svg';
import OpenSVG from '../../../assets/icons/item_open.inline.svg';
import { useToggle } from '../../../hooks/useToggle';
import { ShowMore } from '../showMore';

import { Filter, Expanded, Root, Title, Icon } from './filters.styles';
import { FiltersProps } from './filters.types';

export const Filters = ({
  activeFilter,
  filters,
  onChange,
  isExpandable,
}: FiltersProps) => {
  const expandedOver = 5;
  const [isShowMore, toggleShowMore] = useToggle(false);
  const [isExpanded, toggleExpanded] = useToggle(false);

  return (
    <Root>
      {isExpandable && (
        <Title>
          <span>Filter</span>
          <Icon>
            {isExpanded ? (
              <OpenSVG onClick={toggleExpanded} />
            ) : (
              <CloseSVG onClick={toggleExpanded} />
            )}
          </Icon>
        </Title>
      )}
      {!isExpanded && (
        <>
          {filters.slice(0, expandedOver).map((filter) => (
            <Filter
              onClick={() => onChange(filter)}
              active={filter === activeFilter}
            >
              {filter}
            </Filter>
          ))}
          <Expanded isShowMore={isShowMore}>
            {filters.slice(expandedOver).map((filter) => (
              <Filter
                onClick={() => onChange(filter)}
                active={filter === activeFilter}
              >
                {filter}
              </Filter>
            ))}
          </Expanded>
          {filters.length > expandedOver && (
            <ShowMore isShowMore={isShowMore} toggleShowMore={toggleShowMore} />
          )}
        </>
      )}
    </Root>
  );
};
