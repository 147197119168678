import React from 'react';

import { ShowMoreContainer, Icon, Close, Open } from './showMore.styles';
import { ShowMoreProps } from './showMore.types';

export const ShowMore = ({ isShowMore, toggleShowMore }: ShowMoreProps) => {
  return (
    <ShowMoreContainer onClick={toggleShowMore}>
      <span>Show {isShowMore ? 'Less' : 'More'}</span>
      <Icon>
        <Close isShowMore={isShowMore} />
        <Open isShowMore={isShowMore} />
      </Icon>
    </ShowMoreContainer>
  );
};
