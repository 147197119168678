import styled from 'styled-components';

import { getColor, Color } from '../../../theme/color';
import {
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
  getUnitAsPixels,
  getMediaQuery,
  ScreenSize,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Root = styled.div`
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    bottom: VerticalSpacingVariant.SMALL,
  })}
  ${getMediaQuery(ScreenSize.LARGE)} {
    position: sticky;
    top: ${getUnitAsPixels({ multiplier: 2 })};
  }
`;

export const Title = styled.div`
  ${getMediaQuery(ScreenSize.LARGE)} {
    display: none;
  }
  margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};
  ${getTypography(Type.BODY_REGULAR)}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Filter = styled.div`
  color: ${({ active }: { active: boolean }) =>
    getColor(active ? Color.RED : Color.BLACK)};
  cursor: pointer;
  ${getTypography(Type.HEADING_3)}
  line-height: 1.3;

  :hover {
    color: ${getColor(Color.RED)};
  }
`;

export const Expanded = styled.div<{ isShowMore: boolean }>`
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${({ isShowMore }) => (isShowMore ? `1000px` : 0)};
  opacity: ${({ isShowMore }) => (isShowMore ? 1 : 0)};
`;
